<template>
  <v-card
    outlined
    class="mt-4 bordered"
    elevation="1"
    :loading="loading"
    :height="height"
  >
    <div class="card-content">
      <v-card-text>
        <div class="d-flex">
          <div class="w-50">
            <div>
              <slot name="number"></slot>
            </div>
            <div>
              <slot name="name"></slot>
            </div>
          </div>
          <div class="w-50">
            <div>
              <slot name="description"></slot>
            </div>
            <div>
              <slot name="rut"></slot>
            </div>
          </div>
        </div>

        <v-divider style="background: #d9d9d9" class="my-4"></v-divider>
        <div>
          <slot name="detail"></slot>
          <slot name="button"></slot>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "SideBarCards",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      default: "200",
      type: [String, Number],
    },
  },
  components: {},
  computed: {},
  methods: {},
};
</script>
<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";

.pointer {
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize;
}
</style>
