<template>
  <v-card class="bordered overflow-hidden" elevation="1" height="305">
    <div class="pa-4 py-8 card-content">
      <div class="d-flex">
        <div>
          <Icon name="person" size="23px" />
        </div>
        <div class="d-flex flex-column ml-2">
          <h5 class="text-capitalize">
            {{
              userType == "NAT"
                ? userData?.cliente?.nombre ??
                  "" + " " + userData?.cliente?.primer_apellido ??
                  "" + " " + userData?.cliente?.segundo_apellido ??
                  ""
                : userData?.cliente?.razon_social ?? ""
            }}
          </h5>

          <div class="d-flex">
            <h6 class="bold" v-if="country === countries.CL">Rut:</h6>
            <h6 class="bold" v-if="country === countries.PE">Tipo doc:</h6>
            <span class="h6 ml-2">{{
              userData?.cliente?.numero_documento ?? "" | rutFormat
            }}</span>
          </div>

          <template v-if="userType == 'NAT'">
            <!-- FECHA DE NACIMIENTO -->
            <div class="d-flex">
              <h6 class="bold">Fecha de nac.:</h6>
              <span class="h6 ml-2">
                {{
                  userData?.cliente?.fecha_nacimiento ?? "" | VMask(dateMask)
                }}
              </span>
            </div>
            <div class="d-flex">
              <!-- GENERO -->
              <h6 class="bold">Genero:</h6>
              <span class="h6 ml-2">{{
                genreList.find((e) => e.value === userData?.cliente?.genero)
                  .text
              }}</span>
            </div>
          </template>

          <div class="d-flex">
            <h6 class="bold">Teléfono:</h6>
            <span class="h6 ml-2">{{
              userData?.[userType == "NAT" ? "cliente" : "contacto"]
                ?.datos_contacto?.telefono ?? ""
            }}</span>
          </div>
          <div class="d-flex">
            <h6 class="bold">Correo:</h6>
            <div class="h6 ml-2">
              {{
                userData?.[userType == "NAT" ? "cliente" : "contacto"]
                  ?.datos_contacto?.email ?? ""
              }}
            </div>
          </div>
          <div class="d-flex">
            <h6 class="bold">Cliente desde:</h6>
            <span class="h6 ml-2">{{
              userData?.fecha_creacion ?? "" | formatDate
            }}</span>
          </div>

          <!-- <div class="d-flex">
            <h6 class="bold">Comuna:</h6>
            <span class="h6 ml-2">{{
              userData?.comuna_descripcion ?? ""
            }}</span>
          </div> -->

          <p class="mb-3">
            <span class="bold h6">Extra info.: </span>
            <span class="h6 ml-2">
              {{
                userData?.[userType == "NAT" ? "cliente" : "contacto"]
                  ?.datos_contacto?.otro || "- - -"
              }}
            </span>
          </p>
        </div>
      </div>
      <div class="d-flex justify-center" v-if="editClient">
        <v-btn
          rounded
          color="primary"
          class="text-none"
          small
          outlined
          @click="editUserBtn()">
          <v-icon left>mdi-pencil</v-icon>
          Editar
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import Icon from "@/components/Icon/Icon.vue";
import { dateMask } from "@/helpers/mask.js";
import { genreList } from "@/store/resources/constants.js";
import { COUNTRIES } from "@/helpers/constants";

export default {
  props: {
    userData: {
      type: [Object, Array],
      default: null,
    },
    editClient: {
      type: Boolean,
      default: true,
    },
    userType: {
      type: String,
      default: "NAT",
    },
  },
  components: {
    Icon,
  },
  data: () => ({
    countries: COUNTRIES,
  }),
  methods: {
    editUserBtn() {
      this.$emit("edit");
    },
  },
  computed: {
    dateMask: () => dateMask,
    genreList: () => genreList,
    country() {
      return this.$store.getters.getCountry;
    },
  },
};
</script>
