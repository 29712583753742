<template>
  <div>
    <div class="pl-7 pr-10 py-10">
      <v-card flat class="invisible d-flex flex-column">
        <h4 class="mb-3" v-if="user?.cliente">Pendientes del cliente</h4>
        <p class="font-weight-bold text-capitalize subtitle-1">
          {{
            `${user?.cliente?.nombre ?? ""} ${
              user?.cliente?.primer_apellido ?? ""
            }`
          }}
        </p>
        <div class="d-flex pb-5" v-if="user?.cliente">
          <p
            class="font-weight-bold subtitle-2"
            v-if="country === countries.CL">
            RUT:
            <span class="ml-2 font-weight-regular">{{
              user?.cliente?.numero_documento ?? "" | rutFormat
            }}</span>
          </p>

          <p
            class="font-weight-bold subtitle-2"
            v-if="country === countries.PE">
            {{ user.cliente?.tipo_documento }}:
            <span class="ml-2 font-weight-regular">{{
              user?.cliente?.numero_documento ?? "" | rutFormat
            }}</span>
          </p>
        </div>

        <!-- <v-divider class="mb-6"></v-divider> -->
        <div v-if="!user?.cliente">
          <v-progress-circular
            indeterminate
            color="primary"></v-progress-circular>
        </div>
        <QuotationPending
          v-if="showQuotes"
          :loading="loadingQuotes"
          :quotations="quotations"
          :rut="user?.cliente?.numero_documento ?? ''" />
        <DocumentPending
          v-if="showDocs"
          :class="quotations.length > 0 ? 'mt-5' : 'mt-1'"
          :loading="loadingDocs"
          :documents="documents"
          :rut="user?.cliente?.numero_documento ?? ''" />
      </v-card>
    </div>
  </div>
</template>

<script>
import QuotationPending from "@/components/User-Components/Pending/PendingQuot";
import DocumentPending from "@/components/User-Components/Pending/PendingDocs";
import { COUNTRIES } from "@/helpers/constants";

export default {
  props: {
    documents: {
      type: Array,
      default: () => [],
      description: "Documentos pendientes",
    },
    quotations: {
      type: Array,
      default: () => [],
      description: "Simulaciones pendientes",
    },
    showDocs: {
      type: Boolean,
      default: true,
      description: "Mostrar documentos pendientes",
    },
    showQuotes: {
      type: Boolean,
      default: true,
      description: "Mostrar Simulaciones pendientes",
    },
    loadingDocs: {
      type: Boolean,
      default: false,
    },
    loadingQuotes: {
      type: Boolean,
      default: false,
    },
    user: {
      type: [Object, Array],
      default: null,
      description: "Datos del cliente",
    },
  },
  data: () => ({
    countries: COUNTRIES,
  }),
  components: {
    QuotationPending,
    DocumentPending,
  },
  computed: {
    country() {
      return this.$store.getters.getCountry;
    },
  },
};
</script>
